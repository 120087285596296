:root {
  --header-table-spacing: 0rem;
}

body {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  margin-top: 0.625rem;
}

.nav-bar {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  height: 2.5rem;
  margin: 0 0 0 0.625rem;
}

.nav-bar a {
  color: #000;
  text-align: center;
  padding: 0.625rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Times New Roman', Times, serif;
}

.nav-bar a:hover {
  background-color: lightgreen;
  color: black;
}

.nav-bar a.active {
  background-color: #4CAF50;
  color: white;
}

.divider, .bottom-divider {
  width: calc(100% - 1.25rem);
  border-top: 1px solid #000;
  margin: 0.625rem 0 0;
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
}

.about-me, .closed-deals {
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
}

.about-me h1, .closed-deals h1 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 1rem;
  padding-bottom: 0.5rem;
}

.deals-table-container {
  width: calc(100% - 1rem);
}

.deals-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.deals-table th,
.deals-table td {
  border: 1px solid #e0e0e0;
  padding: 0.3125rem; 
  text-align: left;
}

.deals-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  position: relative;
  resize: horizontal;
  overflow: auto;
}

.deals-table th::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0.25rem;
  background-color: #ddd;
  cursor: col-resize;
}

.deals-table tr {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.deals-table tr:hover {
  background-color: #f0f0f0;
}

.deals-table .company-name {
  display: flex;
  align-items: center;
}

.deals-table .company-name a {
  text-decoration: none;
  color: #0066cc;
  margin-left: 0.5rem;
}

.deals-table .company-name a:hover {
  text-decoration: underline;
}

.company-logo {
  width: 1.25rem;
  height: auto;
  margin-right: 0.5rem;
}

.deals-table .company-column { width: 50%; }  
.deals-table .segment-column { width: 25%; }  
.deals-table .deal-size-column {
  width: 25%; 
  text-align: right;
}

.icon-placeholder {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #000;
  border-radius: 0;
  margin-left: 0;
}

.deals-table td:nth-child(1) { text-align: left; }
.deals-table td:nth-child(2) { text-align: center; }
.deals-table td:nth-child(3) { text-align: right; }

.footer {
  font-family: 'Times New Roman', Times, serif !important;
  margin-top: 0px;
  padding: 0rem;
  text-align: left;
  margin-left: 0.625rem;
}
