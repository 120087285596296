@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: 'Times New Roman', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-size: 16px; /* Base font size for rem calculations */
}

.App {
  text-align: center;
}

.nav {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: normal;
  width: 30%;
}

.nav ul li {
  cursor: pointer;
  padding: 0;
  text-align: center;
}

.nav ul li:hover {
  background-color: #42b96a;
}

.content {
  padding: 0;
}

.home {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  text-align: left;
  padding: 0;
  font-family: 'Times New Roman', sans-serif;
}

.home p {
  margin: 1.2rem 0;
  font-family: 'Times New Roman', sans-serif;
  font-size: 0.875rem; /* 14px to rem */
}

.headshot {
  width: 18.75rem; /* 300px to rem */
  height: auto;
  border-radius: 1%;
  margin-bottom: 1.25rem; /* 20px to rem */
}

.meme-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.meme-container {
  position: relative;
  background-color: white;
  padding: 0.625rem; /* 10px to rem */
  border-radius: 0.5rem; /* 8px to rem */
  text-align: center;
}

.meme-image {
  max-width: 100%;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: -1.25rem; /* -20px to rem */
  right: -1.25rem; /* -20px to rem */
  background: none;
  border: none;
  font-size: 1.5rem; /* 24px to rem */
  color: rgb(255, 255, 255);
  cursor: pointer;
}

@media (max-width: 48rem) { /* 768px */
  .nav ul {
    width: 100%;
    flex-direction: column;
  }

  .headshot {
    width: 100%;
    max-width: 18.75rem;
  }

  .home p {
    font-size: 1rem;
  }
}