@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

.surf-breaks {
  font-family: 'Times New Roman', Times, serif;
  margin-top: 10px;
  height: 100vh;
  overflow-y: auto;
}

.surf-breaks-content {
  margin-left: 10px; /* Add padding to the left for header and paragraph text */
}

.surf-breaks-header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0px;
}

.surf-breaks-paragraph {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.map-container {
  width: 90%;
  height: 400px;
  position: relative; /* Ensure relative positioning for absolute elements */
}

.map-style-selector-container {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  margin-top: 5px;
  margin-bottom: 5px;
}

.map-style-selector {
  background-color: lightgreen; /* Container background color */
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.map-style-selector label {
  margin-right: 10px;
  font-size: 12px;
  font-family: 'Times New Roman', Times, serif; /* Ensure font family */
}

.map-style-selector select {
  padding: 2px;
  font-size: 10px;
  font-family: 'Times New Roman', Times, serif; /* Ensure font family */
}

.divider {
  width: calc(100% - 20px); /* Full width minus left and right margins */
  margin: 10px auto; /* Add left and right margins */
  border: none;
  border-top: 1px solid #000;
}

form {
  display: flex;
  flex-direction: row; /* Align inputs horizontally */
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

form input,
form button {
  font-family: 'Times New Roman', Times, serif; /* Updated font-family */
  margin-bottom: 10px;
  padding: 6px;
  margin-right: 10px; /* Add space between inputs */
}

form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 0; /* No margin after the button */
}

form button:hover {
  background-color: lightgreen;
  color: black;
}

.surf-breaks-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}

.surf-breaks-table th,
.surf-breaks-table td {
  border: none;
  padding: 2px 8px; /* Decrease padding for a more compact table */
  line-height: 1.3; /* Decrease line-height for a more compact table */
  text-align: center;
  font-family: 'Times New Roman', Times, serif; /* Updated font-family */
}

.surf-breaks-table th {
  background-color: #ffffff;
  font-weight: bold;
  font-size: 14px; /* Decrease font size */
  color: black;
  text-align: center; /* Align text to the left */
  padding: 8px 12px;
}

.surf-breaks-table tr {
  border-bottom: 1px solid #eaeaea;
}

.surf-breaks-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.surf-breaks-table tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.surf-breaks-table tr:last-child {
  border-bottom: none;
}

.surf-breaks-table tr:hover {
  background-color: #f2f2f2;
}

.surf-breaks-table .company-name {
  display: flex;
  align-items: center;
}

.surf-breaks-table .company-name a {
  text-decoration: underline;
  color: inherit; /* Keep the color consistent with the rest of the text */
  margin-left: 4px; /* Decrease margin */
}

.surf-breaks-table a:hover {
  text-decoration: underline;
}

.icon-placeholder {
  display: inline-block;
  width: 12px; /* Decrease icon size */
  height: 12px; /* Decrease icon size */
  background-color: #000; /* Placeholder color, change it as needed */
  border-radius: 50%; /* To make it look like an icon */
}

/* Align the text in the Name column to the left */
.surf-breaks-table td:nth-child(1) {
  text-align: center;
}

/* Align the text in the Surf Break Name column to the center */
.surf-breaks-table td:nth-child(2) {
  text-align: center;
}

/* Align the text in the Latitude column to the right */
.surf-breaks-table td:nth-child(3) {
  text-align: center;
}

/* Align the text in the Longitude column to the right */
.surf-breaks-table td:nth-child(4) {
  text-align: center;
}

/* Align the text in the Rating column to the right */
.surf-breaks-table td:nth-child(5) {
  text-align: center;
}

/* Navbar styles */
.nav-bar {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 40px;
}

.nav-bar a {
  color: #000; /* Text color */
  text-align: center; /* Center the text */
  padding: 10px 16px; /* Padding around the text */
  text-decoration: none; /* Remove underline */
  font-size: 12px; /* Font size */
  font-family: 'Times New Roman', Times, serif; /* Ensure font family */
}

.nav-bar a:hover {
  background-color: lightgreen; /* Change background on hover */
  color: black; /* Change text color on hover */
}

.nav-bar a.active {
  background-color: #4CAF50; /* Active link background */
  color: white; /* Active link text color */
}

.nav-icon {
  font-size: 24px; /* Size of the icon */
  color: #000; /* Color of the icon */
  margin-right: 10px; /* Space between the icon and the first link */
}

.bottom-divider {
  width: calc(100% - 20px); /* Full width minus left and right margins */
  border: none;
  border-top: 1px solid #000;
  margin: 10px 0;
}

.footer {
  font-family: 'Times New Roman', Times, serif !important;
  margin-top: 0px;
  font-size: 14px; /* Ensure the font size is consistent */
  margin-left: 10px;
}
