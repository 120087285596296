/* src/components/Deals.css */
.deals-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start */
  margin-top: 0.625rem;
  margin-right: 0;
  font-family: 'Times New Roman', Times, serif;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  overflow: auto; /* Allow scrolling */

}

.deals-content {
  text-align: left;
  width: 80%; /* Adjust width to take up the remaining screen space */
  margin-left: 0%; /* Ensure content starts after the navbar */
  flex-grow: 1; /* Ensure deals-content takes up available space */
  margin-bottom: 0;
  padding: 0.625rem;
}

.deals-content p {
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
}

.deals-content h1 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
  margin-top: 0;
  padding-left: 0.625rem; /* Add padding to the left */
}

.deals-table-container {
  margin-top: 1.25rem;
  margin-left: 1.875rem;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: center; /* Center the table horizontally */
}

.deals-table {
  width: 100%;
  border-collapse: collapse;
  margin: auto; /* Center the table within its container */
  font-size: 0.75rem;
  font-family: 'Times New Roman', Times, serif;
}

.deals-table th,
.deals-table td {
  border: none;
  padding: 0.125rem 0.5rem; /* Adjust the padding to reduce space between columns */
  line-height: 1.2;
}

.deals-table th {
  background-color: white;
  font-weight: bold;
  font-size: 0.75rem;
  color: black;
}

.deals-table tr {
  border-bottom: 0.625rem solid #ffffff;
}

.deals-table tr:last-child {
  border-bottom: none;
}

.deals-table tr:hover {
  background-color: #f2f2f2;
}

.deals-table .company {
  display: flex;
  align-items: center;
  text-align: left !important; /* Force align text to the left */
}

.deals-table .company a {
  text-decoration: underline;
  color: rgb(68, 68, 255) !important; /* Ensure this is blue */
  margin-left: 0.625rem;
}

.deals-table .company-logo {
  width: 1.25rem; /* Adjust width as needed */
  height: auto;
  margin-left: 0.625rem; /* Space between logo and text */
}

.deals-table td.description {
  text-align: left !important; /* Force align description cells to the left */
}

.deals-table a:hover {
  text-decoration: underline;
}

.blue-link {
  color: blue;
  text-decoration: underline;
  font-size: 0.75rem; /* Ensure the font size is consistent with surrounding text */
}

.divider {
  width: calc(100% - 1.25rem); /* Full width minus left and right margins */
  border: none;
  border-top: 1px solid #000;
  margin-bottom: 0.625rem;
  margin-top: 0;
}

.footer {
  margin-top: 0.625rem; /* Adjust margin to ensure footer is visible */
  font-size: 0.875rem; /* Ensure the font size is consistent */
  margin-left: 0.625rem;
}

.bottom-divider {
  width: calc(100% - 1.25rem); /* Full width minus left and right margins */
  border: none;
  border-top: 1px solid #000;
  margin: 0px 0; /* Adjust margin to create space around the divider */
  padding: 0px 0; /* Adjust padding as needed */
  margin-bottom: 0px;
}
