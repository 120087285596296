/* src/components/Books.css */
.books-page {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.625rem;
  font-family: 'Times New Roman', Times, serif;
  background-color: #fff;
  height: 100vh;
  overflow: auto;
}

.books-content {
  text-align: left;
  width: 80%;
  margin-left: 1.875rem;
  margin-top: 0;
  flex-grow: 1;
  margin-bottom: 0;
}

.books-content p {
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
}

.books-content h1 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
  margin-top: 0;
  padding-left: 1.25rem;
}

.books-list, .articles-list {
  list-style: disc;
  padding: 0;
  margin-left: 1.25rem;
}

.book-item, .article-item {
  margin-bottom: 0.625rem;
}

.book-link, .article-link {
  text-decoration: underline;
  color: #4444ff;
  margin-left: 0.625rem;
}

.divider {
  width: calc(100% - 1.25rem);
  border: none;
  border-top: 1px solid #000;
  margin-bottom: 0.625rem;
}

.bottom-divider {
  width: calc(100% - 1.25rem);
  border: none;
  border-top: 1px solid #000;
  margin: 0.625rem 0 0 0.625rem;
}

.footer {
  font-family: 'Times New Roman', Times, serif;
  margin-top: 0;
  font-size: 0.875rem;
  margin-left: 0.625rem;
}

.intro-text {
  font-size: 0.875rem;
  margin-left: 1.875rem;
}
