.nav-bar {
  font-size: 0.875rem;
  background-color: white;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center;
  padding-left: 0px; /* Uniform padding on the left */
  padding-right: 0.625rem; /* Uniform padding on the right */
  height: 3.125rem;
  margin-bottom: 0.625rem; /* Ensure uniform spacing */
}

.nav-bar a {
  color: #000; /* Text color */
  text-align: center; /* Center the text */
  padding: 0.625rem; /* Padding around the text */
  text-decoration: none; /* Remove underline */
  font-size: 0.75rem; /* Font size */
  font-family: 'Times New Roman', Times, serif; /* Ensure font family */
  margin: 0 0.3125rem; /* Uniform spacing between links */
  white-space: nowrap; /* Prevent text from wrapping */
}

.nav-bar a:hover {
  background-color: lightgreen; /* Change background on hover */
  color: black; /* Change text color on hover */
}

.nav-bar a.active {
  background-color: #4CAF50; /* Active link background */
  color: white; /* Active link text color */
}

.nav-icon {
  font-size: 1.5rem; /* Size of the icon */
  color: #000; /* Color of the icon */
  margin-right: 0.625rem; /* Space between the icon and the first link */
}
